import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import { Button, Loading, useRefresh } from 'react-admin';
import IconCancel from '@material-ui/icons/Cancel';
import ViewIcon from '@material-ui/icons/RemoveRedEye';
import StartIcon from '@mui/icons-material/Start';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import parse from 'html-react-parser';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ViewJobDrawer extends Component {
    state = {
      showPanel: false,
      job : {},
      message: undefined,
      messageList: [],
      errors: [],
      isLoading: true,
      data: {},
      isComplete: false,
      progress: 0,
      showMessages: 'all',
    };
    buttons = [
      <ToggleButton value="all" >Show All</ToggleButton>,
      <ToggleButton value="success" >Show Successes</ToggleButton>,
      <ToggleButton value="failures" >Show Failures</ToggleButton>,
    ];
    handleMessagesToggle = (event, newMessageToggle) => {      
      this.setState({showMessages: newMessageToggle});      
      var id = this.props.id      
      this.loadJobAndTasks(id)
    };
    fetchJob = async (jobId) => {
        const token = localStorage.getItem('tap-token');
        const isLicensingJob = this.props.isLicensingJob ? true : false
        const requestOptions = {
           method: 'GET',
           headers: { 'Content-Type': 'application/json', Authorization: token},
        };
        return await fetch(process.env.REACT_APP_API_URL + '/jobs/' + jobId + '?isLicensingJob=' + isLicensingJob, requestOptions)
        .then(response => response.json())
        .then(responsData => {
          return responsData
        }).catch(({ status, statusText}) => {
          console.log("Caught status status ", status);
          console.log("Caught status statusText ", statusText);
          return []
        });
    };
    fetchJobTasks = async (jobId) => {
        const token = localStorage.getItem('tap-token');
        const isLicensingJob = this.props.isLicensingJob ? true : false
        const requestOptions = {
           method: 'GET',
           headers: { 'Content-Type': 'application/json', Authorization: token},
        };
        return await fetch(process.env.REACT_APP_API_URL + '/jobs/tasks/' + jobId + '?isLicensingJob=' + isLicensingJob, requestOptions)
        .then(response => response.json())
        .then(responsData => {
            return responsData.sort((a, b) => parseInt(a.taskDetailsId) - parseInt(b.taskDetailsId));
        }).catch(({ status, statusText}) => {
          console.log("Caught status status ", status);
          console.log("Caught status statusText ", statusText);
          return []
        });
    };
    loadJobAndTasks = async (jobId) => {
        const job = await this.fetchJob(jobId);
        const jobTasks = await this.fetchJobTasks(jobId)

        var jobTasksGrouped = [];

        var alphaMap = {
          0: 0, 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8, 9: 9, 
          a: 1, b: 2, c: 3, d: 4, e: 5, f: 6, g: 7, h: 8, i: 9, j: 10, k: 11, l: 12, m: 13, n: 14, o: 15, p: 16, q: 17, r: 18, s: 19, t: 20, u: 21, v: 22, w: 23, x: 24, y: 25, z: 26}

      jobTasks
          .sort((a,b) => a.taskDetailsId < b.taskDetailsId ? -1 : 1 )
          .map(jobTask => {
          //const taskGroupKey = jobTask.taskDetailsId % 100000
          var ans = 0;          
          for (var i = 1; i < jobTask.taskDetails.length; ++i) {
            var mappedNumber = alphaMap[jobTask.taskDetails[i].toLowerCase()]
            if (!mappedNumber) mappedNumber = 27 // assign 27 to non alpha values
            ans += mappedNumber;
          }         
          var taskGroupKey = ans                    
          if (!jobTasksGrouped[taskGroupKey]) {
            jobTasksGrouped[ taskGroupKey] = {}
            jobTasksGrouped[ taskGroupKey ]['messages'] = []
            jobTasksGrouped[ taskGroupKey]['taskDetails'] = ""
            jobTasksGrouped[ taskGroupKey]['taskStatus'] = ""
            jobTasksGrouped[ taskGroupKey]['taskCreated'] = ""
          }
          var indivMessages = jobTask.statusMessage.split('\n')

          if (jobTask.statusMessage != null && (jobTask.statusMessage.indexOf('Processing') >= 0 || jobTask.statusMessage.indexOf('Found') >= 0 )) {
            indivMessages.map(indivMessage => {
              var item =
                  "<span class='job-success-" + (indivMessage != null && (indivMessage.indexOf('Success') >= 0 || indivMessage.indexOf('Processing') >= 0  || indivMessage.indexOf('Found') >= 0)) + "'>" + indivMessage + "</span>"

                if(indivMessage.indexOf('Processing') >= 0  || indivMessage.indexOf('[Error]') >= 0 || indivMessage.indexOf('Found') >= 0 ) {
                    jobTasksGrouped[ taskGroupKey]['taskDetails'] = jobTask.taskDetails
                    jobTasksGrouped[ taskGroupKey]['messages'].push(item)
                    jobTasksGrouped[ taskGroupKey]['taskCreated'] = jobTask.createdAt
                } else if(indivMessage.length > 0 && ((this.state.showMessages === 'all') ||
                    (this.state.showMessages === 'success' && indivMessage != null && (indivMessage.indexOf('Success') >= 0  )))) {
                  jobTasksGrouped[ taskGroupKey]['taskDetails'] = jobTask.taskDetails                               
                  jobTasksGrouped[ taskGroupKey]['messages'].push(item)
                  jobTasksGrouped[ taskGroupKey]['taskCreated'] = jobTask.createdAt
               } else if(indivMessage.length > 0 && this.state.showMessages === 'failures' &&
                   indivMessage != null && (indivMessage.indexOf('Failed') >= 0 || indivMessage.indexOf('Skipped')) >= 0  ) {
                jobTasksGrouped[taskGroupKey]['messages'].push(item)
                jobTasksGrouped[ taskGroupKey]['taskStatus'] = jobTask.taskStatus 
                jobTasksGrouped[ taskGroupKey]['taskDetails'] = jobTask.taskDetails
                jobTasksGrouped[ taskGroupKey]['taskCreated'] = jobTask.createdAt
              }        
              return indivMessage      
            })
          } else {         
            //console.log('jobTask.statusMessage > 0 ' + (jobTask.statusMessage != null && jobTask.statusMessage.indexOf('Created') >= 0))            
            indivMessages.map(indivMessage => { 
            var item = "<span class='job-success-" + (indivMessage != null && (indivMessage.indexOf('Created') >= 0 || indivMessage.indexOf('Success') >= 0)) + "'>" + indivMessage + "</span>"
              if (indivMessage.length > 0 && (this.state.showMessages === 'all' || (this.state.showMessages === 'success' && indivMessage != null && (indivMessage.indexOf('Created') >= 0 || indivMessage.indexOf('Success') >= 0 )))) {
                  jobTasksGrouped[taskGroupKey]['messages'].push(item)
                  jobTasksGrouped[taskGroupKey]['taskDetails'] = jobTask.taskDetails
                  jobTasksGrouped[taskGroupKey]['taskStatus'] = jobTask.taskStatus
                  jobTasksGrouped[taskGroupKey]['taskCreated'] = jobTask.createdAt
              } else if(indivMessage.length > 0 && (this.state.showMessages === 'failures' && indivMessage != null && (indivMessage.indexOf('Failed') >= 0 || indivMessage.indexOf('Skipped')) >= 0)) {
                  jobTasksGrouped[taskGroupKey]['messages'].push(item)
                  jobTasksGrouped[taskGroupKey]['taskDetails'] = jobTask.taskDetails
                  jobTasksGrouped[taskGroupKey]['taskCreated'] = jobTask.createdAt
              }           
              return indivMessage   
            })

          }
          return jobTask
      })
      var messageList = []
      var index = 0      
      jobTasksGrouped = jobTasksGrouped.sort((a,b) => a.taskCreated < b.taskCreated ? -1 : 1 )
      jobTasksGrouped
          .map(groupedTasks => {            
            if (groupedTasks.messages.length > 0) {
             return messageList.push(<ListItem className={`groupedTasks-Header`} key={`groupedTasks-${index++}`}>
                 <ListItemText primary={<span>{groupedTasks.taskDetails}</span>} secondary={<span
                     className={`job-message`}>{parse(groupedTasks.messages.join("<br/>"))}</span>}/>
             </ListItem>)
            }
            return groupedTasks
      })
      this.setState({
        job: job,
        data: jobTasks,
        jobTasksGrouped: jobTasksGrouped,
        messageList: messageList,
        showPanel: true,
        isLoading: false,
        isComplete: job.jobStatus !== 'RUNNING' && job.jobStatus !== 'CREATED' && job.jobStatus !== 'STARTING' && job.jobStatus !== 'RETRYING',
        progress: job.progressCount > 0 && job.targetCount > 0 ? (job.progressCount / job.targetCount) * 100 : 0
      })
      if (job.jobStatus !== 'RUNNING' && job.jobStatus !== 'CREATED' && job.jobStatus !== 'STARTING' && job.jobStatus !== 'RETRYING' && job.jobStatus !== 'QUEUED_UPDATED' &&  job.jobStatus !== 'QUEUED_UPDATED_USER_INITIATED')
        clearInterval(this.state.jobInterval);
    }
    startUpdateEngine = async () => {
        const token = localStorage.getItem('tap-token');
        const requestOptions = {
           method: 'POST',
           headers: { 'Content-Type': 'application/json', Authorization: token},
        };
        return await fetch(process.env.REACT_APP_API_URL + '/jobs/update-engine/start', requestOptions)
        .then(response => response.json())
        .then(responsData => {
          return responsData
        }).catch(({ status, statusText}) => {
          console.log("Caught status status ", status);
          console.log("Caught status statusText ", statusText);
          return 0
        });
    };
    handleClickStartUpdateEngine = async () => {
      this.setState({
        showPanel: true,
        job : {},
        message: undefined,
        messageList: [],
        errors: [],
        isLoading: true,
        data: {},
        isComplete: false,
        progress: 0
      });
      const jobId = await this.startUpdateEngine();
      console.log("startUpdateEngine jobId ", jobId)
      if (isNaN(jobId)) {        
        var errorMessage = <div><div className="toastTitle">Error</div><div className="toastMainMessage">{jobId.error.message}</div></div>
        toast.error(errorMessage, {
          position: toast.POSITION.BOTTOM_CENTER,
          closeButton: true,
          autoClose: false
        });
        this.setState({           
          showPanel: false,
          isLoading: false
        });
      } else {
        var id = jobId
        var self = this
        var jobInterval = setInterval(function() {self.loadJobAndTasks(id)}, 2000);
        this.setState({ jobInterval: jobInterval });
      }      
    };
    handleClick = async () => {
      this.setState({
        showPanel: true,
        job : {},
        message: undefined,
        messageList: [],
        errors: [],
        isLoading: true,
        data: {},
        isComplete: false,
        progress: 0
      });
      var id = this.props.id
      var self = this
      var jobInterval = setInterval(function() {self.loadJobAndTasks(id)}, 2000);
      this.setState({ jobInterval: jobInterval });
      document.getElementById('JobRefresh').className = 'NoRefresh'      
    };
    handleCloseClick = (event: object, reason: string) => {      
      if (reason == null || reason !== 'backdropClick') {
        clearInterval(this.state.jobInterval);
        document.getElementById('REFRESH').click()
        document.getElementById('JobRefresh').className = 'Refresh'
        this.setState({ showPanel: false });
      }
    };
    render() {
        function Refresh() {
          const refresh = useRefresh();
          return (
            <>
              <Button id='REFRESH' style={{ display: "none" }} onClick={() => refresh()} />
            </>
          )
        }
        function LinearProgressWithLabel(props) {
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} color={ props.status === 'FAILED' ? "error" : props.status === 'COMPLETE' ? 'success' : 'primary' } />
              </Box>
              <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" style={{ color: props.status === 'FAILED' ? "red" : props.status === 'COMPLETE' ? 'green' : 'blue' }} color="text.secondary">{`${Math.round(
                  props.value,
                )}%`}</Typography>
              </Box>
            </Box>
          );
        }
        const { showPanel, job, isLoading, messageList, progress } = this.state;
        return (
            <Fragment>
                <div id="JobRefresh" className="Refresh" style={{ display: "none" }}>                  
                </div>
                <div id="ViewIcon" className="ViewIcon">
                  { this.props.status !== "READY" && this.props.id && <Button label={this.props.title} onClick={this.handleClick}><ViewIcon/></Button> }
                  { this.props.status !== "READY" && !this.props.id && this.props.title === 'Start Update Engine' && <Button label={this.props.title} onClick={this.handleClickStartUpdateEngine}><StartIcon/></Button> }
                </div>                
                <Drawer                    
                    disableEscapeKeyDown
                    anchor="right"
                    open={showPanel}
                    onClose={this.handleCloseClick}
                >
                    {isLoading ? <Loading/> : <div style={{ marginLeft: '30px', marginRight: '40px' }}>
                      <Fragment>                        
                            <Typography style={{ fontSize: "28px "}} variant="subtitle1" color="inherit" >Job View</Typography>
                            <hr />                            
                            {!this.props.hideProgress && <Typography style={{ fontSize: "17px ", padding: "25px"}} color="inherit" >Job {job.id} : Status {job.jobStatus === "QUEUED_UPDATED" ? 'QUEUED FOR UPDATES' : job.jobStatus === "QUEUED_UPDATED_USER_INITIATED" ? 'QUEUED FOR UPDATES USER INITIATED' : job.jobStatus} : Progress : {job.progressCount} : Target : {job.targetCount} </Typography>}
                            {this.props.hideProgress && <Typography style={{ fontSize: "17px ", padding: "25px"}} color="inherit" >Job {job.id} : Status {job.jobStatus} : Deals Processed : {job.progressCount} </Typography>}
                            {!this.props.hideProgress && <LinearProgressWithLabel value={progress} status={job.jobStatus} /> }
                            <div style={{ marginBottom: '10px' }}/> <hr />
                            {this.props.title !== 'Start Update Engine' && <ToggleButtonGroup value={this.state.showMessages} exclusive={true} onChange={this.handleMessagesToggle} size="medium" aria-label="vertical outlined button group"
                              >
                                {this.buttons}
                            </ToggleButtonGroup>}
                            {messageList && messageList.map(message => message)}
                            <div style={{ zIndex: 1, textAlign: 'center', position: 'fixed', bottom: '0', float: 'left', right: '0' }}>
                              <Button                                
                                style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '20px'}}
                                label="Close"
                                onClick={this.handleCloseClick}>
                                  <IconCancel />
                             </Button>
                          </div>
                      </Fragment>
                    </div>}
                    <Refresh/>
                </Drawer>
            </Fragment>
        );
    }
}
const JobView = connect()(ViewJobDrawer);
export default JobView
