import React, {Fragment, useEffect, useState} from 'react';
import { useCallback } from 'react';
import { Link, SimpleForm, required, Edit, Create, Toolbar, SaveButton,
         TextInput, useNotify,DateInput, AutocompleteInput,
         ArrayInput, SimpleFormIterator, Button, useRedirect,
         FormDataConsumer, ReferenceInput, useMutation, CheckboxGroupInput, BooleanInput
       } from 'react-admin';
import Box from '@material-ui/core/Box';
import Moment from 'moment-timezone';
import AddIcon from '@material-ui/icons/AddBoxRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import RunJob from './RunButton'
import InactivateJob from './InactivateButton'
//import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useFormState } from 'react-final-form';

const EditCreateLicensingBreadCrumb = ({ record }) => {
  return  <div className="breadcrumbs"><Link to={"/licensing/list"}>Licensing Job</Link> &nbsp;>{record && record.id > 0 ? ` Edit Licensing Job: ${record.id} ` : ' Create'}</div>;
};
const EditCreateTitle = ({ record }) => {
  return <span>{record && record.id > 0 ? `Edit Licensing Job: ${record.id} ` : 'Create Licensing Job'}</span>;
 };

const checkStartEndDate = v => {
  var startDate = document.getElementById("startDate").value
  var endDate = document.getElementById("endDate").value
  if(Moment(endDate).isBefore(startDate)){
    return "End Date cannot be before Start Date"
  }
 };

const validateDate = [required(), checkStartEndDate];

class LicensingForm extends React.Component {
  render() {
    return (
        <Fragment>
          <Box display="flex" style={{ width: '98%', paddingLeft: '1%' }}>
            <EditCreateLicensingBreadCrumb {...this.props} />
          </Box> </Fragment>
    )}
}

const RunJobButton = ({ record = {} }) => <RunJob label='Run' id={record.id} />
const InactivateJobButton = ({ record = {} }) => <InactivateJob label='Inactivate' id={record.id} />

const CustomAddButton = (props) => {
    const formState = useFormState();
    const catalogs = formState.values.catalogs;
    const [notificationShown, setNotificationShown] = useState(false);

    useEffect(() => {
        if (catalogs && catalogs.length >= 99999 && !notificationShown) {
            setNotificationShown(true);
        } else if (catalogs && catalogs.length < 99999 && notificationShown) {
            setNotificationShown(false);
        }
    }, [catalogs, notificationShown]);

    if (catalogs && catalogs.length >= 99999) {
        return <AddNotificationButton label={"Add Catalog"}/>;
    }
    return <AddButton {...props} />;
}

export const AddNotificationButton = props => {
    const notify = useNotify();
    const handleNotification = () => {
        notify('A maximum of 99999 titles may be added to a Licensing Job.', 'error');
    };

    return <Button {...props} style={{position: 'bottom', cursor: 'pointer', color: 'green'}} margin="normal" onClick={handleNotification}>
        <AddIcon label="+" style={{cursor: 'pointer', color: 'green'}}/>
    </Button>;
}

export const AddButton = props => (
    <Button {...props} style={{ position: 'bottom', cursor: 'pointer', color: 'green'}} margin="normal">
      <AddIcon label="+" style={{ cursor: 'pointer', color: 'green'}}  />
    </Button>
);

const CustomToolbarEdit = props => (
    <Toolbar {...props}>
        <SaveButton/>
        <InactivateJobButton {...props}/>
    </Toolbar>
);
const CustomToolbarCreate = props => (
  <Toolbar {...props}>
      <SaveButton/>
  </Toolbar>
);
const dateParser = v => {
  if (v) {
    var localFormat = 'YYYY-MM-DD';
    return Moment(Moment(v).local().format(localFormat)).format('YYYY-MM-DD');
  } else {
    return null
  }
};

export const LicensingCreate = props => {
  const notify = useNotify();
  const [mutate] = useMutation();
  const redirect = useRedirect();
  const save = useCallback(
       async (values) => {
           try {
             values.status = "CREATED"
             document.getElementById("LicensingError").innerHTML = ""
               await mutate({
                   type: 'create',
                   resource: 'licensing',
                   payload: { id: values.id, data: values },
               }, {
                 returnPromise: true,
                 onSuccess: ({ data }) => {
                   notify("Licensing Job Created", {
                        undoable: false,
                        messageArgs: { resource: 'post' }
                   })
                   redirect('/licensing/list')
                   return true
                 },
                 onFailure: ({ err }) => {
                   console.log("inside onFailure of mutate ", err)
                   notify("Error on Save", {
                        type: "error",
                        undoable: false,
                        messageArgs: { resource: 'post' }
                   })
                 }
               });
             } catch (error) {
                 if (error.body.errors) {
                    if (error.body.errors.licensingError)
                      document.getElementById("LicensingError").innerHTML = error.body.errors.licensingError
                 }
                 return error.body.errors;
             } finally {
               return
             }
       },
       [mutate, notify, redirect],
  );

  return (
    <div id="LicensingForm">
      <Create undoable={false} title={<EditCreateTitle />} {...props}>
        <SimpleForm {...props} save={save} toolbar={<CustomToolbarCreate />}>
        <LicensingForm {...props} />
          <Fragment>
          <Box display="flex" style={{ width: '98%', paddingLeft: '1%' }}>
          </Box>
          <Box display="flex" justifyContent="center" style={{ width: '80%', paddingLeft: '1%' }}>
            <TextInput options={{ fullWidth: true }} label="Licensing Job Description" source="description" validate={required("Description cannot be blank")}  />
          </Box>
        </Fragment>
          <div id="LicensingError" style={{ color: '#f44336',  width: '98%', paddingLeft: '1%', fontSize: '0.75rem' }}></div>
          <Box display="flex" justifyContent="space-between" style={{ width: '80%', paddingLeft: '1%', float: 'left' }}>
            <ArrayInput source="catalogs" label="Catalog" defaultValue={[{ id: ""}]}>
              <SimpleFormIterator disableReordering addButton={<CustomAddButton label='Add Catalog' />} removeButton={<RemoveCircleRoundedIcon  style={{ cursor: 'pointer', color: 'red'}} />}>
              <FormDataConsumer>
                  {({ getSource }) => {
                   return (
                      <>
                      <ReferenceInput perPage={100} label="Search by ID or Title" source={getSource('id')} reference="licensing/searchCatalogItemsAPI" validate={required("Catalog cannot be blank")}>
                        <AutocompleteInput optionText="searchableTitle" resettable/>
                      </ReferenceInput>
                      </>
                    );
                  }}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          </Box>
          <Box display="flex" justifyContent="space-between" style={{ width: '80%', paddingLeft: '1%', float: 'left' }}>
            <div id='DealsOnJob' style={{ width: '100%'}}>
              <ArrayInput source="deals" label="Deal (Optional)" defaultValue={[{ id: ""}]}>
                <SimpleFormIterator disableReordering addButton={<AddButton label='Add Deal' />} removeButton={<RemoveCircleRoundedIcon  style={{ cursor: 'pointer', color: 'red'}}  fullWidth/>}>
                <FormDataConsumer>
                    {({ getSource }) => {
                    return (
                        <ReferenceInput perPage={100} label="Search by ID or Deal Name" source={getSource('id')} reference="licensing/searchDealsAPI">
                          <AutocompleteInput optionText="searchableTitle" resettable/>
                        </ReferenceInput>                        
                      );
                    }}
                  </FormDataConsumer>
                </SimpleFormIterator>
              </ArrayInput>
            </div>
          </Box>
          <Box display="flex" justifyContent="space-between" style={{ width: '80%', paddingLeft: '1%', float: 'left' }}>
            <ArrayInput source="medias" label="Media" defaultValue={[{ id: ""}]}>
              <SimpleFormIterator disableReordering addButton={<AddButton label='Add Media' />} removeButton={<RemoveCircleRoundedIcon  style={{ cursor: 'pointer', color: 'red'}} />}>
                <FormDataConsumer>
                  {({ getSource }) => {
                   return (
                      <>
                          <ReferenceInput perPage={100} label="Media" reference="licensing/getMediaTerritoryList/media" source={getSource('id')} validate={required()}>
                          <AutocompleteInput optionText="label" resettable/>
                          </ReferenceInput>
                      </>
                    );
                  }}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
            <ArrayInput source="territories" label="Territory" defaultValue={[{id: ""}]}>
              <SimpleFormIterator disableReordering addButton={<AddButton label='Add Territory' />} removeButton={<RemoveCircleRoundedIcon  style={{ cursor: 'pointer', color: 'red'}} />}>
                <FormDataConsumer>
                  {({ getSource }) => {
                   return (
                      <>
                          <ReferenceInput perPage={100} label="Territory" reference="licensing/getMediaTerritoryList/territory" source={getSource('id')} validate={required()}>
                            <AutocompleteInput optionText="label" resettable/>
                          </ReferenceInput>
                      </>
                    );
                  }}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          </Box>
          <Fragment>
          <Box display="flex" justifyContent="space-between" style={{ width: '78%', paddingLeft: '2%' }}>
            <DateInput defaultValue={"01/01/1900"} id="startDate" options={{ fullWidth: true }} label="Start Date" source="startDate" validate={validateDate}  />
          <Box display="flex" justifyContent="center" style={{ width: '1%', paddingLeft: '2%' }}/>
            <DateInput defaultValue={"12/31/3000"} id="endDate" options={{ fullWidth: true }} label="End Date" source="endDate" validate={validateDate} />
          </Box>
          </Fragment>
          <Fragment>
          <Box display="flex" justifyContent="space-between" style={{ width: '500px', paddingLeft: '1%' }}>
          <CheckboxGroupInput label="Licensee Type" validate={required()} source="licenseeTypeList" choices={[
              { _id: 'GLOBAL', label: 'Global (Amazon, Apple, Google, Microsoft, Vubiquity)' },
              { _id: 'LOCAL', label: 'Local (all other Licensees)' },                           
          ]} optionValue="_id" optionText="label" />          
          </Box>
          </Fragment>
 
        </SimpleForm>
      </Create>
    </div>
  );
};

export const LicensingEdit = props => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [mutate] = useMutation();
  const save = useCallback(
       async (values) => {
              if(values.status === "COMPLETE"){
                document.getElementById("LicensingError").innerHTML = ""
                /*
                confirmAlert({
                  title: 'Are you sure you wish to save the job?',
                  message: 'All the existing unpublished offers for this job will be replaced.',
                  buttons: [
                    {
                      label: 'Yes',
                      onClick: () => {
                          try{
                            mutate({
                              type: 'update',
                              resource: 'licensing',
                              payload: { id: values.id, data: values },
                            }, {
                            returnPromise: true,
                            onSuccess: ({ data }) => {
                            notify("Licensing Job Updated", {
                                    undoable: false,
                                    messageArgs: { resource: 'post' }
                              })
                            refresh()
                            return true
                            },
                            onFailure: ({ err }) => {
                              notify("Error to update", {
                                    type: "error",
                                    undoable: false,
                                    messageArgs: { resource: 'post' }
                              })
                            }
                          });
                          } catch (error) {
                            console.log("Caught error ", error.body.errors)
                            if (error.body.errors) {
                              if (error.body.errors.licensingError)
                                document.getElementById("LicensingError").innerHTML = error.body.errors.licensingError
                            }
                            return error.body.errors;
                            } finally {
                              return
                            }
                      }
                    },
                    {
                      label: 'No',
                      onClick: () => console.log("User cancelled change")
                    }
                  ]
                });
                */
                await mutate({
                    type: 'update',
                    resource: 'licensing',
                    payload: { id: values.id, data: values },
                  }, {
                  returnPromise: true,
                  onSuccess: ({ data }) => {
                    notify("Licensing Job Updated", {
                          undoable: false,
                          messageArgs: { resource: 'post' }
                    })
                    redirect('/licensing/list')
                    return true
                  },
                  onFailure: ({ err }) => {
                    notify("Error to update", {
                          type: "error",
                          undoable: false,
                          messageArgs: { resource: 'post' }
                    })
                  }
                });
             }else{
               try{
                await mutate({
                    type: 'update',
                    resource: 'licensing',
                    payload: { id: values.id, data: values },
                  }, {
                  returnPromise: true,
                  onSuccess: ({ data }) => {
                    notify("Licensing Job Updated", {
                          undoable: false,
                          messageArgs: { resource: 'post' }
                    })
                    redirect('/licensing/list')
                    return true
                  },
                  onFailure: ({ err }) => {
                    notify("Error to update", {
                          type: "error",
                          undoable: false,
                          messageArgs: { resource: 'post' }
                    })
                  }
                });
                } catch (error) {
                  console.log("Caught error ", error.body.errors)
                  if (error.body.errors) {
                    if (error.body.errors.licensingError)
                      document.getElementById("LicensingError").innerHTML = error.body.errors.licensingError
                  }
                  return error.body.errors;
                  } finally {
                    return
                  }
             }
       },
       [mutate, notify, redirect],
  );

  return (
    <div id="LicensingForm">
    <Edit undoable={false} title={<EditCreateTitle />}  {...props}>
      <SimpleForm {...props} save={save} toolbar={<CustomToolbarEdit {...props}/>}>
      <LicensingForm {...props} />
      <RunJobButton {...props}/>
      <Fragment>
          <Box display="flex" style={{ width: '98%', paddingLeft: '1%' }}>
          </Box>
          <Box display="flex" justifyContent="right" style={{ width: '30%', paddingLeft: '1%' }}>
            <TextInput disabled label="Status" source="status"/>
          </Box>
          <Box display="flex" justifyContent="center" style={{ width: '90%', paddingLeft: '1%' }}>
            <TextInput options={{ fullWidth: true }} label="Licensing Job Description" source="description" validate={required("Description cannot be blank")}  />
          </Box>
        </Fragment>
          <div id="LicensingError" style={{ color: '#f44336',  width: '98%', paddingLeft: '1%', fontSize: '0.75rem' }}></div>
          <Box display="flex" justifyContent="space-between" style={{ width: '90%', paddingLeft: '1%', float: 'left' }}>
          <ArrayInput source="catalogs" label="Catalog">
            <SimpleFormIterator disableReordering addButton={<CustomAddButton label='Add Catalog' />} removeButton={<RemoveCircleRoundedIcon  style={{ cursor: 'pointer', color: 'red'}} />}>
              <FormDataConsumer>
                {({ getSource }) => {
                 return (
                    <>
                        <ReferenceInput perPage={100} label="Search by ID or Title" reference="licensing/searchCatalogItemsAPI" source={getSource('id')} validate={required()}>
                          <AutocompleteInput optionText="searchableTitle" />
                        </ReferenceInput>
                    </>
                  );
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
          </Box>
          <Box display="flex" justifyContent="space-between" style={{ width: '80%', paddingLeft: '1%', float: 'left' }}>
            <div id='DealsOnJob' style={{ width: '100%'}}>
              <ArrayInput source="deals" label="Deal (Optional)" defaultValue={[{ id: ""}]}>
                <SimpleFormIterator TransitionProps={{ enter: false, exit: false }} disableReordering addButton={<AddButton label='Add Deal' />} removeButton={<RemoveCircleRoundedIcon  style={{ cursor: 'pointer', color: 'red'}} fullWidth />}>
                <FormDataConsumer>
                    {({ getSource }) => {
                    return (
                        
                        <ReferenceInput key={`deal-${getSource('id')}`} perPage={100} label="Search by ID or Deal Name" source={getSource('id')} reference="licensing/searchDealsAPI">
                          <AutocompleteInput optionText="searchableTitle" resettable/>
                        </ReferenceInput>
                        
                      );
                    }}
                  </FormDataConsumer>
                </SimpleFormIterator>
              </ArrayInput>
            </div>
          </Box>
          <Box display="flex" justifyContent="space-between" style={{ width: '90%', paddingLeft: '1%', float: 'left' }}>
          <ArrayInput source="medias" label="Media">
            <SimpleFormIterator disableReordering addButton={<AddButton label='Add Media' />} removeButton={<RemoveCircleRoundedIcon  style={{ cursor: 'pointer', color: 'red'}} />}>
              <FormDataConsumer>
                {({ getSource }) => {
                 return (
                    <>
                      <ReferenceInput key={`media-${getSource('id')}`} perPage={100} label="Media" reference="licensing/getMediaTerritoryList/media" source={getSource('id')} validate={required()}>
                        <AutocompleteInput optionText="label" resettable/>
                        </ReferenceInput>
                    </>
                  );
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput source="territories" label="Territory">
            <SimpleFormIterator disableReordering addButton={<AddButton label='Add Territory' />} removeButton={<RemoveCircleRoundedIcon  style={{ cursor: 'pointer', color: 'red'}} />}>
              <FormDataConsumer>
                {({ getSource }) => {
                 return (
                    <>
                        <ReferenceInput perPage={100} label="Territory" reference="licensing/getMediaTerritoryList/territory" source={getSource('id')} validate={required()}>
                          <AutocompleteInput optionText="label" resettable/>
                        </ReferenceInput>
                    </>
                  );
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
          </Box>
          <Fragment>
          <Box display="flex" justifyContent="space-between" style={{ width: '88%', paddingLeft: '2%' }}>
            <DateInput id="startDate" options={{ fullWidth: true }} format={dateParser} parse={dateParser} label="Start Date" source="startDate" validate={validateDate}  />
          <Box display="flex" justifyContent="center" style={{ width: '1%', paddingLeft: '2%' }}/>
            <DateInput id="endDate" options={{ fullWidth: true }} format={dateParser} parse={dateParser} label="End Date" source="endDate" validate={validateDate}  />
          </Box>
          </Fragment>

          <Fragment>
            <Box display="flex" justifyContent="space-between" style={{ width: '20%', paddingLeft: '1%', float: "right" }}>             
              <BooleanInput source="enableTaskTracking" valueLabelTrue="Task Tracking Enabled" valueLabelFalse="Task Tracking Disabled" />
            </Box>          
            <Box display="flex" justifyContent="space-between" style={{ width: '40%', paddingLeft: '1%', float: "left"  }}>
            <CheckboxGroupInput label="Licensee Type" validate={required()} source="licenseeTypeList" choices={[
                { _id: 'GLOBAL', label: 'Global (Amazon, Apple, Google, Microsoft, Vubiquity)' },
                { _id: 'LOCAL', label: 'Local (all other Licensees)' },                           
            ]} optionValue="_id" optionText="label" />          
            </Box>          
          </Fragment>

          
          <Box display="flex" justifyContent="center" style={{ width: '100%', clear: 'both' }}>
          </Box>

        
          
      </SimpleForm>
    </Edit>
    </div>
  );
};
